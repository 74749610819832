<template>
  <div>
    <p class="title">顧客登入</p>
    <hr />
    <!-- 社群登入註冊 -->
    <div class="customer-social-login row mb-4 mx-auto">
      <p class="mb-3 tw-text-size20 fw-bolder">社群註冊登入</p>
      <div class="col-6 fb-login">
        <img
          class="pointer"
          src="@/assets/icon/facebook-icon.png"
          alt="FB"
          @click="FbLogin"
        />
      </div>
      <div class="col-6">
        <img
          class="pointer"
          src="@/assets/icon/line.png"
          alt="Line"
          @click="loginWithLine"
        />
      </div>
      <div class="col-12 mt-3">
        <!-- 條款 -->
        <div
          class="
            text-start
            fw-bolder
            d-flex
            flex-column
            justify-content-center
            align-items-center
          "
        >
          <div class="form-check">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              id="CheckPrivacy"
              v-model="checkLaw.privacy"
            />
            <label class="form-check-label" for="CheckPrivacy">
              <router-link
                class="text-dark pointer"
                to="/ETwingo/privacy"
                target="_blank"
                >我已詳閱並同意隱私權政策</router-link
              >
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              id="CheckService"
              v-model="checkLaw.service"
            />
            <label class="form-check-label" for="CheckService">
              <router-link
                class="text-dark pointer"
                to="/ETwingo/serve"
                target="_blank"
                style=""
                >我已詳閱並同意服務&ensp;&ensp;條款</router-link
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- 或/or -->
    <div class="or-line mb-3">
      <span></span>
      或/or
      <span></span>
    </div>
    <!-- 一般登入 -->
    <form class="mb-3" @submit.prevent="systemLogin">
      <!-- 帳號 -->
      <div class="form-floating mb-3">
        <input
          type="email"
          class="form-control"
          id="Account"
          placeholder="帳號"
          v-model="login.account"
        />
        <label for="Account">帳號</label>
      </div>
      <!-- 密碼 -->
      <div class="form-floating mb-3">
        <input
          type="password"
          class="form-control"
          id="Password"
          placeholder="密碼"
          v-model="login.password"
          maxlength="40"
        />
        <label for="Password">密碼</label>
      </div>
      <button class="form-btn" type="submit">登入</button>
    </form>
    <hr />
    <!-- 其他 -->
    <div class="row mb-3">
      <a
        class="shine pointer fw-bolder col-6"
        href="/sellerLogin"
        target="_blank"
      >
        <p>我是賣家</p>
        <p>前往登入</p>
      </a>
      <!-- 忘記密碼 -->
      <div class="col-6">
        <a href="/forgotPassword">忘記密碼</a>
        <div class="mt-3">
          <a class="fw-bolder" style="cursor: pointer" @click="goTeachingPage"
            >Easy To Go 前往官網使用教學</a
          >
          <p class="mt-2">版本號: {{ packageConfig.version }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mixin
import { Login } from '@/methods/mixins/login'

export default {
  mixins: [Login]
}
</script>